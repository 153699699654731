html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.nav-slider .slick-list {
  overflow: visible;
}

.nav-slider .slick-slide {
  opacity: 0.4;
}

.nav-slider .slick-active {
  opacity: 1;
}

.nav-slider .slick-prev:before,
.nav-slider .slick-next:before {
  display: none;
}

.uspa-section .slick-track {
  display: flex;
  align-items: center;
}


.video-gradiant {
  position: relative;
}

.video-gradiant::after {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.announcementbar .slick-track {
  display: flex
}

@media screen and (min-width:425px) and (max-width:479px) {
.custom-space{
  gap: 40px;
}
}